import { Injectable } from '@angular/core';
import { IFeatureDynamic } from '../models/feature-dynamic.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureDynamicService implements IFeatureDynamic {
  /**
   * Whether the feature media gallery (MEDIA_GALLERY) is enabled or not.
   */
  readonly mediaGallery: boolean;
  /**
   * Whether the feature member directory (MEMBER_DIRECTORY) is enabled or not.
   */
  readonly memberDirectory: boolean;

  /**
   * Constructor initializing data
   */
  constructor() { 
    this.mediaGallery = (environment.featuresDynamicDisabled?.indexOf('MEDIA_GALLERY') === -1) ? true : false;
    this.memberDirectory = (environment.featuresDynamicDisabled?.indexOf('MEMBER_DIRECTORY') === -1) ? true : false;
  }
}
